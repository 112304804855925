<template>
  <span>

    <!--Main Page-->
    <b-card>
      <div slot="header">
        <span class="d-flex w-100 justify-content-between">
          <span>
            <h5 style="margin-bottom: 0" @click="toggleMultiSelectMode"><b>Image Manager</b></h5>
          </span>
          <span style="display: inline-block; white-space: nowrap">
            Constellation:
            <b-form-select v-model="curConstellation" :options="constellations" style="width: fit-content"
                           @input="getImages"></b-form-select>
            <fa-icon :icon="['fas', 'plus']" size="lg" style="padding-left: 10px; cursor: pointer;"
                     @click="openUpload"/>
            <fa-icon v-if="multiSelect.selected.length > 1" :icon="['fas', 'trash-can']" size="lg"
                     style="padding-left: 10px; cursor: pointer;" @click="multiSelect.areYouSure = true"/>
          </span>
        </span>
      </div>
      <v-client-table v-if="curConstellation !== ''" style="width: 100%" :columns="dataTable.columns" :data="images"
                      ref="dTable"
                      :options="dataTable.options" :theme="dataTable.theme" class="dataTable">
        <span slot="h__edit" style="float: right">View/Edit</span>
        <span slot="edit" slot-scope="props" style="display: inline-block; white-space: nowrap; float: right;">
          <fa-icon :icon="['fas', 'image']" size="lg" @click="openViewModal(props.row)" style="cursor: pointer;"/>
          <fa-icon :icon="['fas', 'pen-to-square']" size="lg" style="padding-left: 10px; cursor: pointer;"
                   @click="openEditModal(props.row)"></fa-icon>
        </span>
        <img slot="image" slot-scope="props" style="height: 70px"
             :src="url(props.row.image)"/>
        <div slot="h__check">
          <b-form-checkbox type="checkbox" style="padding-left: 24px" class="check-all" v-model='multiSelect.allMarked'
                           @change="multiSelect.selected = unmarkAndMarkAll(multiSelect.selected, multiSelect.allMarked)"></b-form-checkbox>
        </div>
        <b-form-checkbox slot-scope="props" slot="check" v-model="multiSelect.selected"
                         :value="props.row"></b-form-checkbox>
      </v-client-table>
      <p v-else style="text-align: center">Please select a constellation</p>
    </b-card>

      <!--Image Viewer-->
    <b-modal title="Image Viewer" class="modal-dark" v-model="viewModal" ok-only size="lg">
      <b-card style="padding-left: 0; overflow: hidden; position: relative;" class="align-center" no-body>
        <b-card-img style="object-fit: contain" :src="url(curImage)"/>
      </b-card>
    </b-modal>

      <!--Upload Image-->
    <b-modal no-close-on-backdrop scrollable title="New Image" class="modal-dark" v-model="upload.modal">
      <span style="display: inline-block; white-space: nowrap">
        Constellation:
        <b-form-select v-model="curConstellation" :options="constellations" style="width: fit-content"
                       @input="getImages"></b-form-select>
      </span>
      <br>
      <br>
      <p v-if="curConstellation.length < 1" style="text-align: center">Please select a constellation</p>
      <input v-else-if="upload.dataImageData.length === 0" type="file" id="files" ref="files"
             v-on:change="handleFileUpload()" multiple accept="image/*"/>
      <div v-if="upload.dataImageData.length !== 0">
        <div v-for="(image,index) in upload.dataImageData" :key="index">
          <b-row>
            <b-col md="3">
              <img style="width: 110px; height: 70px; object-fit: contain" :src="makeUrl(upload.dataImageData[index])"/>
            </b-col>
            <b-col md="9">
              <span><b>Name for "{{ image.name }}":</b></span>
              <b-input :placeholder="image.name" v-model="upload.dataImageName[index]"></b-input>
            </b-col>
          </b-row>
          <br>
        </div>
        <br>
        <span @click="upload.dataImageName = [], upload.dataImageData = ''"
              style="display: inline-block; white-space: nowrap">
          <fa-icon :icon="['fas', 'backward']" size="lg" style="padding-left: 10px; cursor: pointer;"/>
          <span> Go Back</span>
        </span>
      </div>
      <template slot="modal-footer">
        <b-button size="sm" variant="secondary" @click="closeUpload">Cancel</b-button>
        <b-button size="sm" variant="primary" @click="uploadSubmit()"
                  :disabled="upload.dataImageData.length === 0">Submit</b-button>
      </template>
    </b-modal>

      <!--Edit Image-->
    <b-modal no-close-on-backdrop scrollable title="New Image" class="modal-dark" v-model="edit.modal">
      <b-card style="padding-left: 0; overflow: hidden; position: relative;" class="align-center" no-body>
        <b-card-img v-if="edit.curImage !== null" style="object-fit: contain" :src="url(edit.curImage.image)"/>
      </b-card>
      <strong>Name:</strong>
      <b-form-input v-model="edit.tempName"></b-form-input>
      <template slot="modal-footer">
        <b-button size="sm" variant="danger" style="width: fit-content;margin-right: auto"
                  @click="edit.areYouSure = true">Delete Image</b-button>
        <b-button size="sm" variant="secondary" @click="closeEdit">Cancel</b-button>
        <b-button size="sm" variant="primary" @click="putImage">Submit</b-button>
      </template>
    </b-modal>

      <!--Are You Sure Delete-->
    <b-modal no-close-on-backdrop scrollable title="Confirm Delete" class="modal-dark" v-model="edit.areYouSure">
      <p>Are you Sure You want to delete this photo?</p>
      <template slot="modal-footer">
        <b-button size="sm" variant="secondary" @click="closeEdit">Cancel</b-button>
        <b-button size="sm" variant="danger" @click="deleteImage(edit.curImage._id)">Delete Image</b-button>
      </template>
    </b-modal>

      <!--Are You Sure Delete Many-->
    <b-modal no-close-on-backdrop scrollable title="Confirm Delete" class="modal-dark" v-model="multiSelect.areYouSure">
      <p><strong>Are you Sure You want to delete these photos?</strong></p>
      <ul v-for="(image, index) in multiSelect.selected" :key="index">
        <li>{{ image.name }}</li>
      </ul>
      <template slot="modal-footer">
        <b-button size="sm" variant="secondary" @click="multiSelect.areYouSure = false">Cancel</b-button>
        <b-button size="sm" variant="danger" @click="multiDelete()">Delete Image</b-button>
      </template>
    </b-modal>

    <b-modal no-close-on-backdrop hide-footer hide-header title="Loading" v-model="upload.loadModal">
      <b-row>
        <b-col md="2" style="font-size: 40px">
          <fa-icon :icon="['fas', 'spinner']" size="lg" spin style="margin-left: 17px"/>
        </b-col>
        <b-col md="10">
          <span style="font-size: 40px; text-align: center">Uploading Images...</span>
        </b-col>
      </b-row>
    </b-modal>

  </span>
</template>

<script>
import constellation from '@/services/constellation';
import blackhole from '@/services/blackhole';
import {ClientTable} from 'vue-tables-2';
import Vue from 'vue';
import constellationStore from "@/store/constellationStore";

Vue.use(ClientTable);

export default {
    name: "image_upload",

    props: {
        constellation: {
            type: String,
            default: null
        },
        deviceId: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            constellations: [],
            curConstellation: "",
            curImage: "",
            dataTable: {
                columns: ['name', 'image', 'edit'],
                options: {
                    orderBy: {column: 'order', ascending: true},
                    headings: {
                        name: 'Name',
                        preview: 'Edit',
                    },
                    sortable: ['name'],
                    filterable: ['name'],
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll'
                    },
                    perPage: 25,
                    skin: 'table table-striped table-hover',
                },
                useVuex: false,
                theme: 'bootstrap4',
                template: 'default',
            },
            multiSelect: {
                toggle: false,
                allMarked: false,
                selected: [],
                areYouSure: false
            },
            edit: {
                modal: false,
                tempName: "",
                curImage: null,
                areYouSure: false,
            },
            images: [],
            upload: {
                modal: false,
                dataImageData: '',
                dataImageName: [],
                tempInfo: [],
                count: 1,
                count2: 0,
                loadModal: false,
            },
            viewModal: false,
        }
    },

    methods: {
        closeEdit() {
            this.edit.modal = false;
            this.edit.tempName = "";
            this.edit.curImage = null;
            this.edit.areYouSure = false;
        },
        closeUpload() {
            this.upload.modal = false;
            this.upload.dataImageData = '';
            this.upload.dataImageName = [];
            this.upload.tempInfo = [];
            this.upload.count = 1;
            this.upload.count2 = 0;
        },
        deleteImage(id) {
            constellation.deleteDISImage(this.curConstellation, id).then(() => {
                this.closeEdit();
                this.getImages();
            });
        },
        getConstellations() {
            constellationStore.dispatch('getConstellations').then(response1 => {
                response1.forEach(constellation => {
                    this.constellations.push({
                        value: constellation.url + ":" + constellation.port,
                        text: constellation.constellationname
                    });
                    this.constellations.sort((a, b) => {
                        return a.text.localeCompare(b.text)
                    });
                });
            });
            this.constellations.sort((a, b) => {
                return a.text.localeCompare(b.text)
            });
            if (this.curConstellation !== "") {
                this.getImages();
            }
        },
        getImages() {
            constellation.readDISImages(this.curConstellation).then(response => {
                if (Array.isArray(response.data)) {
                    this.images = response.data;
                }
                this.upload.loadModal = false;
            });
        },
        makeUrl(file) {
            return (URL.createObjectURL(file))
        },
        multiDelete() {
            this.multiSelect.selected.forEach(i => {
                this.deleteImage(i._id);
            })
            this.multiSelect.areYouSure = false;
        },
        openEditModal(img) {
            this.edit.tempName = img.name;
            this.edit.curImage = img;
            this.edit.modal = true;
            this.edit.areYouSure = false;
        },
        openUpload() {
            this.upload.modal = true;
            this.upload.dataImageData = '';
            this.upload.dataImageName = [];
            this.upload.tempInfo = [];
            this.upload.count = 1;
            this.upload.count2 = 0;
        },
        openViewModal(image) {
            this.curImage = image.image;
            this.viewModal = true;
        },
        postImage(data) {
            constellation.createDISImage(this.curConstellation, data).then(() => {
                this.upload.count2 += 1;
                if (this.upload.count2 === this.upload.dataImageData.length) {
                    this.closeUpload();
                    this.getImages();
                }
            });
        },
        putImage() {
            this.upload.modal = false;
            let data = {name: this.edit.tempName};
            constellation.updateDISImage(this.curConstellation, this.edit.curImage._id, data).then(() => {
                this.closeEdit()
                this.getImages()
            });
        },
        toggleMultiSelectMode() {
            if (!this.multiSelect.toggle) {
                this.dataTable.columns = ['check', 'name', 'image', 'edit'];
                this.multiSelect.toggle = true;
            } else {
                this.dataTable.columns = ['name', 'image', 'edit'];
                this.multiSelect.toggle = false;
                this.multiSelect.selected = [];
            }
        },
        unmarkAndMarkAll(selected, allMarked) {
            if (allMarked === false) {
                let temp = [];
                if (this.$refs.dTable.allFilteredData) {
                    this.$refs.dTable.allFilteredData.forEach(function (item) {
                        temp.push(item);
                    });
                }
                return (temp)
            } else {
                return ([])
            }
        },
        url(image) {
            return blackhole.readImage(image);
        },
        handleFileUpload() {
            this.upload.dataImageData = this.$refs.files.files;
        },
        uploadSubmit() {
            this.upload.loadModal = true
            this.upload.modal = false;
            for (let i = 0; i < this.upload.dataImageData.length; i++) {
                let formData = new FormData();
                let name = this.upload.dataImageName[i]
                let fileName = this.upload.dataImageData[i].name;
                formData.append('file', this.upload.dataImageData[i]);
                blackhole.createObject(formData).then(response => {
                    if (this.upload.dataImageName[i]) {
                        this.postImage({image: response.data, name: name});
                    } else {
                        this.postImage({image: response.data, name: fileName});
                    }
                })

            }
        }
    },
    created() {
        this.getConstellations();
    },
    mounted() {
        if (this.constellation !== null) {
            this.curConstellation = this.constellation;
            this.getImages();
            if (this.deviceId === null) {
                this.upload.modal = true;
            } else {
                constellation.readDISImage(this.curConstellation, this.deviceId).then(response => {
                    this.openEditModal(response.data);
                });
            }
        }
    },
}
</script>

<style scoped>
</style>